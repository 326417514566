<template>
  <div class="developer-request-done">
    <div class="done-container">
      <div class="done">
        <i class="material-icons">check</i>
      </div>
      <div class="done-title">파트너 개발자 신청을 완료하였습니다.</div>
      <div class="done-desc">심사가 완료되면 알림톡으로 알려드려요</div>
      <router-link to="/">
        <button class="button">홈으로</button>
      </router-link>
    </div>
    <div class="done-info">
      <div class="done-info-title">정산 계좌 정보</div>
      <div class="done-info-desc">
        <div class="done-info-desc-item-title">은행명</div>
        <div class="done-info-desc-item-value">{{ bank_info.bank }}</div>
        <div class="done-info-desc-item-title">예금주</div>
        <div class="done-info-desc-item-value">{{ bank_info.name }}</div>
        <div class="done-info-desc-item-title">계좌번호</div>
        <div class="done-info-desc-item-value">{{ bank_info.account_num }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import PartnerAPIMixin from '@/mixins/PartnerAPIMixin'
export default {
  name: "DeveloperRequestDone",
  components: {},
  mixins: [
    PartnerAPIMixin
  ],
  props: {},
  data() {
    return {
      bank_info: {
        name: '',
        bank: '',
        account_num: ''
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.request.partner.get('developer_request').then(res => {
        this.bank_info = res.data.bank_info
      })
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.developer-request-done
 padding 64px 0
 background-color $gray3

.done-container
 background-color white
 padding 40px
 text-align center
 width 548px
 margin 40px auto 16px auto
 .done
   background-color $primary
   width 60px
   height 60px
   display flex
   align-items center
   justify-content center
   color white
   border-radius 8px
   margin 0 auto 24px auto
   i
     font-size 28px
 .done-title
   font-size 22px
   font-weight 700
   color $main
   margin-bottom 8px

 .done-desc
   font-size 16px
   color $sub2
   line-height 24px
   margin-bottom 40px
 .button
   width 100%
   height 56px
   font-size 16px
   font-weight 700
   color $sub

.done-info
  background-color white
  padding 24px
  width 548px
  margin 0 auto
  .done-info-title
    font-size 16px
    font-weight 700
    color $main
    margin-bottom 16px
  .done-info-desc
    display grid
    grid-template-columns 72px 1fr
    gap 8px 12px
    color #242424
    .done-info-desc-item-title
      font-size 14px
      color $sub2
      font-weight 500
    .done-info-desc-item-value
      font-size 14px
      color $sub
@media (max-width: 600px)
 .developer-request-done
  padding 60px 16px
  .done-container
    margin 16px 0
    width 100%
    padding 40px 16px
    .done-title
      font-size 18px
    .done-desc
      font-size 14px
      line-height 22px
  .done-info
    width 100%
</style>
